import {
  Flex,
  Stack,
  Text,
  Group,
  Badge,
  Title,
  Box,
  Anchor,
  Divider,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Delivery, OrderButton } from "./Parts";
import "../App.css";

import DSC01061 from "../assets/DSC01061.jpg";
import DSC01048 from "../assets/DSC01048.jpg";
import DSC01054 from "../assets/DSC01054.jpg";
import DSC01057 from "../assets/DSC01057.jpg";
import DSC01063 from "../assets/DSC01063.jpg";
import DSC01065 from "../assets/DSC01065.jpg";
import DSC01070 from "../assets/DSC01070.jpg";
import DSC01077 from "../assets/DSC01077.jpg";
import dirty from "../assets/dirty.jpg";

export function Home() {
  const desktop = useMediaQuery("(min-width: 1200px)");
  const padding = desktop ? 50 : 20;

  return (
    <Stack className="page" pb={padding}>
      <Flex
        className="nav"
        justify={"space-between"}
        align={"center"}
        pt={16}
        my={desktop ? 30 : 20}
        px={padding}
      >
        <Text className="logo">Water Grate</Text>

        <OrderButton>
          Order Now
        </OrderButton>
      </Flex>

      {!desktop && (
        <Box
          h={500}
          mb={20}
          className="flexGrow"
          style={{
            backgroundImage: `url('${DSC01061}')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      )}

      <Group w={"100%"} px={padding} gap={50} align="top">
        <Stack w={desktop ? "40%" : "auto"} gap={30}>
          <Stack gap="sm">
            <Title order={2} className="product-title">
              Grate Cover for NID Box
            </Title>
            <Text className="para">
              <b style={{ color: "#c17367" }}>The Telescoping Debris Grate that fits over any NID box.</b> Perfect for keeping your sprinklers & filters clear all summer long.
            </Text>
          </Stack>

          <Group gap={10}>
            <Text c={"#777"} size="md"><b>Now just:</b></Text>
            <Text className="price">$12</Text>
            <Badge size="md" c={"#c17367"} color="#f5e1dd">
              60% OFF - Early Bird Discount
            </Badge>
          </Group>

          <OrderButton>
            Continue to Order From
          </OrderButton>

          <Stack>
            <Text c={"#777"} size="md"><b style={{ color: "black" }}>First 25 orders get 60% off!</b></Text>

            <Text c={"#777"} size="md"><b style={{ color: "black" }}>Free local Delivery! Order now, pay after you receive it.</b></Text>
          </Stack>

          <Divider variant="dashed" />

          <Stack gap={32} mt={10}>
            <Stack gap={8}>
              <Title className="heading" order={3}>
                What problems does this solve?
              </Title>

              <Text className="para">
                This custom 3D-printed mesh screen blocks all debris larger than
                1/8 inch in size. It is especially useful when you have impact
                sprinklers or filters downstream from the NID box that are
                easily clogged by acorns, leaves, algae clumps, and other debris
                that often find their way through the box.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                How does it attach to the box?
              </Title>

              <Text className="para">
                The Water Grate is designed to be attached to the front of the NID box. <b style={{ color: "#c17367" }}>Once you place it over the front, the grate telescopes for the perfect fit.</b>
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                How does sizing work?
              </Title>

              <Text className="para">
                Each NID box has a different width. Most are between 30 cm and
                31 cm wide, though some are over 32 cm, and others are less than
                30 cm. Becuase of this, the grate can be adjusted to fit any NID box larger than 29 cm (11.8 in) and less than 33 cm (13 in). If your box is larger than 33 cm, you can order a custom size at no additional cost.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                What are the Grate's dimensions?
              </Title>

              <Text className="para">
                The standard Water Grate is 35 cm tall and can be as wide as 34 cm, fitting a 33 cm wide box.
              </Text>

              <Text className="para">
                The sides of the Water Grate that fit against the side of the
                NID box are 3 cm tall.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                How do local deliveries work?
              </Title>
              <Delivery />
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                Are you able to ship upon request?
              </Title>

              <Text className="para">
                This is outside my capabilities at the moment. If this is
                needed, please use a local friend, family member, or coworker as
                the drop location and have them ship it.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                What if I'm not happy with the Water Grate?
              </Title>

              <Text className="para">
                No problem! I'm happy to refund your order in exchange for the
                Water Grate. If the Grate breaks and you want a replacement, I'm
                happy to do this as well. I do ask that the broken Grate gets
                returned to me so I can review it and incorporate any fixes into
                future iterations. If you ordered the wrong size, I'm happy to
                get the correct size over to you as well. If you are seeking a
                replacement or refund,{" "}
                <Anchor
                  size="sm"
                  href="mailto:info@water-grate.com"
                  target="_blank"
                >
                  please send me a message
                </Anchor>
                .
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                What can I use instead of this product to do the same thing?
              </Title>

              <Text className="para">
                For the last 10 years, I have used hardware cloth of varying
                gauges to create a similar screen. However, fine hardware cloth,
                like ¼-inch, often rusts away to nothing in about 2-3 months.
                Larger hole spacing cloth is not effective at stopping small
                debris from coming through. I'm sure there are other solutions
                out there as well, and I'll keep this section updated as I
                receive more ideas from the community.
              </Text>
            </Stack>

            {!desktop && (
              <Group gap={16} className="flexGrow">
                <Box
                  h={600}
                  className="flexGrow"
                  style={{
                    backgroundImage: `url('${DSC01048}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <Stack gap={16} className="flexGrow" h={"100%"}>
                  <Box
                    style={{
                      flexGrow: 1,
                      height: "100%",
                      backgroundImage: `url('${DSC01065}')`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                  <Box
                    style={{
                      flexGrow: 1,
                      height: "100%",
                      backgroundImage: `url('${DSC01063}')`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                </Stack>
              </Group>
            )}

            <Stack gap={8}>
              <Title className="heading" order={3}>
                How is it made?
              </Title>

              <Text className="para">
                This product is custom 3D-printed on a{" "}
                <Anchor
                  target="_blank"
                  size="sm"
                  href="https://www.prusa3d.com/product/original-prusa-xl-assembled-5-toolhead-3d-printer/"
                >
                  Prusa XL
                </Anchor>{" "}
                in Rough and Ready, CA. These are often printed to order.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                What is it made from?
              </Title>

              <Text className="para">
                This product is made from PETG, a variation of the same plastic
                used in water bottles. This filament is UV resistant and will
                last for years in the sun.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                Can the Water Grate be customized?
              </Title>

              <Text className="para">
                Yes! Get in touch with me here to discuss your exact needs:{" "}
                <Anchor
                  target="_blank"
                  size="sm"
                  href="mailto:info@water-grate.com"
                  className="link"
                >
                  info@water-grate.com
                </Anchor>
                .
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                Can I print this myself?
              </Title>

              <Text className="para">
                Yes, assuming you have a large enough 3D printer, feel free to
                print this. Here is a link to the{" "}
                <Anchor
                  target="_blank"
                  size="sm"
                  href="https://cad.onshape.com/documents/6a255787467cda316914619b/w/4589a3da47d004961383a173/e/018a58cd96e515c9633271ff?renderMode=0&uiState=66d24dad637c5b0d65a5ceec"
                >
                  OnShape 3d files
                </Anchor>
                . I do ask that you not sell this design in Nevada County if you
                do print them yourself. If you want to help with this project,
                please get in touch with me at{" "}
                <Anchor
                  target="_blank"
                  size="sm"
                  href="mailto:info@water-grate.com"
                  className="link"
                >
                  info@water-grate.com
                </Anchor>
              </Text>

              <Text className="para">
                <b style={{ color: "#777" }}>Tips for printing:</b> Select 22%
                grid or beehive infill and remove the top and bottom layers in
                the slicer. It takes about 6 to 11 hours for a Water Grate to
                print.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                What is the motivation behind this project?
              </Title>

              <Text className="para">
                I made this grate for myself after cleaning cloggs out of my
                sprinklers daily for 10 years. The product worked so well that I
                knew I had to get it out into our community. This is just a fun
                little side project. Additionally, designing and selling a
                physical product has been on my bucket list for 9 years.
              </Text>
            </Stack>

            <Stack gap={8}>
              <Title className="heading" order={3}>
                Can I help with this project?
              </Title>

              <Text className="para">
                Sure! If you want to help with this project, please get in touch
                with me at{" "}
                <Anchor
                  size="sm"
                  target="_blank"
                  href="mailto:info@water-grate.com"
                  className="link"
                >
                  info@water-grate.com
                </Anchor>
                .
              </Text>
            </Stack>
          </Stack>

          {!desktop && (
            <Stack mt={10}>
              <Box
                h={600}
                style={{
                  backgroundImage: `url('${dirty}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center bottom",
                }}
              />
              <Box
                h={600}
                style={{
                  backgroundImage: `url('${DSC01070}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center bottom",
                }}
              />
              <Box
                h={600}
                style={{
                  backgroundImage: `url('${DSC01054}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center bottom",
                }}
              />
              <Box
                h={600}
                style={{
                  backgroundImage: `url('${DSC01077}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center bottom",
                }}
              />
              <Box
                h={600}
                style={{
                  backgroundImage: `url('${DSC01057}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center bottom",
                }}
              />
            </Stack>
          )}
        </Stack>

        {desktop && (
          <Stack gap={16} className="flexGrow">
            <Box
              h={500}
              className="flexGrow"
              style={{
                backgroundImage: `url('${DSC01061}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
            <Group gap={16} className="flexGrow">
              <Box
                h={600}
                className="flexGrow"
                style={{
                  backgroundImage: `url('${DSC01048}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
              <Stack gap={16} className="flexGrow" h={"100%"}>
                <Box
                  style={{
                    flexGrow: 1,
                    height: "100%",
                    backgroundImage: `url('${DSC01065}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <Box
                  style={{
                    flexGrow: 1,
                    height: "100%",
                    backgroundImage: `url('${DSC01063}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              </Stack>
            </Group>
            <Box
              h={600}
              style={{
                backgroundImage: `url('${dirty}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center bottom",
              }}
            />
            <Box
              h={600}
              style={{
                backgroundImage: `url('${DSC01070}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center bottom",
              }}
            />
            <Box
              h={600}
              style={{
                backgroundImage: `url('${DSC01054}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center bottom",
              }}
            />
            <Box
              h={600}
              style={{
                backgroundImage: `url('${DSC01077}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center bottom",
              }}
            />
            <Box
              h={600}
              style={{
                backgroundImage: `url('${DSC01057}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center bottom",
              }}
            />
          </Stack>
        )}
      </Group>
    </Stack>
  );
}


