import { Stack, Text, Anchor, Title, Button, Box, Center } from "@mantine/core";
import "../App.css";
import "@mantine/core/styles.css";
import confetti from "canvas-confetti";

export function Success() {
  const end = Date.now() + 2 * 1000;
  const colors = ["#c17367", "#f5e1dd"];
  (function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();

  return (
    <Center className="page">
      <Stack gap={30} mt={"20%"} maw={"400px"}>
        <Stack gap={16}>
          <Title className="product-title" order={1}>
            Thank You!
          </Title>
          <Text>Your order has been placed successfully! We will get started making your Water Grate. </Text>
        </Stack>

        <Stack>
          <Title className="heading" order={4}>
            Questions & Feedback
          </Title>

          <Text>
            If you have any questions, please feel free to reach out to me at{" "}
            <Anchor
              href="mailto:info@water-grate.com"
              target="_blank"
            >
              info@water-grate.com
            </Anchor>
            .
          </Text>

          <Text>
              Last thing, if you are intested in showing my FB Page some love, I
              would greatly appreciate it:
            <br />
            <Anchor
              href="https://www.facebook.com/profile.php?id=61565348187818"
              target="_blank"
            >
              Water Grate Facebook Page
            </Anchor>
            .
          </Text>
        </Stack>

        <Box>
          <Button component="a" variant="light" href="/" color={"#c17367"}>
            Back to Home
          </Button>
        </Box>
      </Stack>
    </Center>
  );
}
