import {
  Alert,
  Box,
  Button,
  Text,
  Divider,
  Group,
  Stack,
  TextInput,
  Textarea,
  Title,
  NumberInput,
  SegmentedControl,
  Slider,
  Transition,
  NativeSelect,
} from "@mantine/core";
import { z } from "zod";
import { useState, useEffect } from "react";
import "../App.css";
import { useMediaQuery } from "@mantine/hooks";
import { Delivery } from "./Parts";

export const formSchema = z.object({
  name: z.string().min(3),
  email: z.string().email(),
  phone: z.string().min(10),

  quantity: z.number().min(1),
  orderDiscount: z.number().min(0).max(80),

  dropoffLocation: z.string().min(7),
  dropoffInstructions: z.string().optional(),

  subTotal: z.number().min(0),
  discount: z.number().min(0),
  total: z.number().min(0),
});

function moneyFormat(amount: number) {
  return Math.round(amount * 100) / 100;
}

export function Order() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",

    quantity: 1,
    orderDiscount: 60,

    dropoffLocation: "",
    dropoffInstructions: "",

    subTotal: 0,
    discount: 0,
    total: 0,
  });
  const [errors, setErrors] = useState({} as any);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);

    const subTotal = formData.quantity * 30;
    const discount = subTotal * (formData.orderDiscount / 100);
    const total = subTotal - discount;

    const orderData = {
      ...formData,
      subTotal: moneyFormat(subTotal),
      discount: moneyFormat(discount),
      total: moneyFormat(total),
    };

    const result = formSchema.safeParse(orderData);
    if (result.success) {
      setErrors({});
      try {
        const isLocal = window.location.hostname === "localhost";
        const url = isLocal ? "http://localhost:8888" : "https://water-grate.com";

        const dataQuery = encodeURIComponent(JSON.stringify(result.data));
        const response = await fetch(`${url}/.netlify/functions/submit-form?data=${dataQuery}`);
        if (response.ok) {
          setIsSubmitted(true);
          setIsSubmitting(false);
          window.location.href = "/success";
        }
      } catch (error) {
        console.error(error);
        setIsSubmitting(false);
      }
    } else {
      // Handle validation errors
      setErrors(result.error.formErrors.fieldErrors);
      console.log(result.error.formErrors.fieldErrors);
      setIsSubmitting(false);
    }
  };

  const [transitions, setTransitions] = useState({
    logo: false,
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    number1: false,
    number2: false,
    number3: false,
    number4: false,
  });

  useEffect(() => {
    const transitionTimings = [
      { key: 'logo', delay: 100 },
      { key: 'step1', delay: 400 },
      { key: 'step2', delay: 600 },
      { key: 'step3', delay: 800 },
      { key: 'step4', delay: 1000 },
      { key: 'number1', delay: 1300 },
      { key: 'number2', delay: 1400 },
      { key: 'number3', delay: 1500 },
      { key: 'number4', delay: 1600 },
    ];

    transitionTimings.forEach(({ key, delay }) => {
      setTimeout(() => {
        setTransitions(prev => ({ ...prev, [key]: true }));
      }, delay);
    });
  }, []);

  const isMobile = useMediaQuery("(max-width: 920px)");

  const [showDelivery, setShowDelivery] = useState(false);

  return (
    <Stack className="page" gap={0} style={{ "backgroundColor": "#efefef" }}>
      <Transition
        mounted={transitions.logo}
        transition="slide-down"
        duration={600}
        timingFunction="ease"
      >
        {(styles) =>
          <Text className="logo" style={{ fontSize: 28, textAlign: "center", ...styles }} pt={50}>
            Water Grate
          </Text>
        }
      </Transition>

      <Stack className="order-page" align="center" justify="top" pt={30} pl={isMobile ? undefined : 30} px={isMobile ? 20 : undefined}>
        <form onSubmit={handleSubmit} style={{ margin: "0 auto" }} >
          <Stack gap={40} mb={300}>

            <Transition
              mounted={transitions.step1}
              transition="slide-up"
              duration={600}
              timingFunction="ease"
            >
              {(styles) =>
                <Group align="center" style={styles}>
                  {!isMobile &&
                    <Transition
                      mounted={transitions.number1}
                      transition="slide-left"
                      duration={400}
                      timingFunction="ease"
                    >
                      {(styles) =>
                        <Box style={styles} className="step-Number">1</Box>}
                    </Transition>
                  }

                  <Stack className="order-section">
                    <Title order={3} mt={10} className="heading">Select Your Water Grates</Title>

                    <Divider variant="dashed" />

                    <Stack gap={20} maw={500} w={"100%"}>
                      <Alert
                        mb={10}
                        color="#c17367"
                        title={<Text size="sm" style={{ "fontWeight": "bold" }} c={"black"}>Will this grate fit my NID Box?</Text>}
                      >
                        Water Grate sizes are adjustable from 29 cm to 33 cm. We guarantee it will fit any standard sized NID Box.
                      </Alert>

                      <Stack gap={4}>
                        <Text size="sm"><b>How many Water Grates would you like?</b></Text>
                        <Group>
                          <Slider
                            style={{ "flexGrow": 1 }}
                            color="#c17367"
                            label="Quantity"
                            value={formData.quantity}
                            onChange={(value) =>
                              setFormData({ ...formData, quantity: Number(value) ?? 0 })
                            }
                            min={0}
                            max={9}
                          />
                          <NumberInput
                            value={formData.quantity}
                            onChange={(value) =>
                              setFormData({ ...formData, quantity: Number(value) ?? 0 })
                            }
                            w={60}
                            min={0}
                            max={9}
                          />
                        </Group>
                      </Stack>

                      <Divider variant="dashed" />

                      {formData.quantity > 0 &&
                        <>
                          <Group justify="space-between">
                            <Text size="sm" style={{ "fontWeight": "bold" }}>Subtotal:</Text>
                            <Text size="sm" style={{ "fontWeight": "bold" }}>${formData.quantity * 30}</Text>
                          </Group>

                          <Alert variant="outline" color="#efefef">
                            <Stack gap={10}>
                              <Text size="sm"><b>Want a discount?</b></Text>

                              {isMobile &&
                                <NativeSelect
                                  data={[
                                    { value: "0", label: "No Discount" },
                                    { value: "20", label: "20% off" },
                                    { value: "40", label: "40% off" },
                                    { value: "60", label: "60% off" },
                                  ]}
                                  onChange={(value) =>
                                    setFormData({ ...formData, orderDiscount: Number(value) ?? 0 })
                                  }
                                  value={formData.orderDiscount.toString()}
                                />
                              }
                              {!isMobile &&
                                <SegmentedControl
                                  data={[
                                    { value: "0", label: "No Discount" },
                                    { value: "20", label: "20% off" },
                                    { value: "40", label: "40% off" },
                                    { value: "60", label: "60% off" },
                                  ]}
                                  onChange={(value) =>
                                    setFormData({ ...formData, orderDiscount: Number(value) ?? 0 })
                                  }
                                  value={formData.orderDiscount.toString()}
                                />
                              }

                              <Text size="sm">
                                This is just a fun side project I am doing with my daughter. If the price gives you a pause, give yourself a discount!
                              </Text>
                            </Stack>
                          </Alert>

                          <Stack gap={10}>
                            <Group justify="space-between">
                              <Text c={"gray"} size="sm" style={{ "fontWeight": "bold" }}>Earily Bird Discount:</Text>
                              <Text c={"gray"} size="sm" style={{ "fontWeight": "bold" }}>-${(formData.quantity * 30) * (formData.orderDiscount / 100)}</Text>
                            </Group>

                            <Group justify="space-between">
                              <Text c={"gray"} size="sm" style={{ "fontWeight": "bold" }}>Local Delivery:</Text>
                              <Text c={"gray"} size="sm" style={{ "fontWeight": "bold" }}>Free</Text>
                            </Group>
                          </Stack>

                          <Divider variant="dashed" />
                        </>
                      }

                      <Group justify="space-between">
                        <Group gap={"xs"} align="center">
                          <Text size="lg" style={{ "fontWeight": "bold" }}>Total:</Text>
                        </Group>
                        <Text size="lg" style={{ "fontWeight": "bold" }}>${(formData.quantity * 30) - ((formData.quantity * 30) * (formData.orderDiscount / 100))}</Text>
                      </Group>
                    </Stack>
                  </Stack>
                </Group>}
            </Transition>

            <Transition
              mounted={transitions.step2}
              transition="slide-up"
              duration={600}
              timingFunction="ease"
            >
              {(styles) =>
                <Group align="center" style={styles}>
                  {!isMobile &&
                    <Transition
                      mounted={transitions.number2}
                      transition="slide-left"
                      duration={400}
                      timingFunction="ease"
                    >
                      {(styles) =>
                        <Box style={styles} className="step-Number">2</Box>}
                    </Transition>
                  }

                  <Stack className="order-section" maw={560} w={"100%"} style={{ flexGrow: 1 }}>
                    <Title order={3} mt={10} className="heading">Dropoff Location</Title>

                    <Divider variant="dashed" />

                    <TextInput
                      label="Physical Dropoff Address"
                      value={formData.dropoffLocation}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          dropoffLocation: event.target.value,
                        })
                      }
                      error={errors.dropoffLocation}
                    />

                    <Textarea
                      label="Dropoff Instructions"
                      value={formData.dropoffInstructions}
                      placeholder="Any special instructions for the dropoff location?"
                      autosize
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          dropoffInstructions: event.target.value,
                        })
                      }
                      error={errors.dropoffInstructions}
                    />

                    <Box mt={10}>
                      <Button size="xs" color="#c17367" variant="light" onClick={() => setShowDelivery(!showDelivery)}>
                        {showDelivery ? "Hide" : "Read"} Local Delivery Guidelines
                      </Button>
                    </Box>

                    {showDelivery && <Stack><Delivery /></Stack>}

                  </Stack>
                </Group>
              }
            </Transition>

            <Transition
              mounted={transitions.step3}
              transition="slide-up"
              duration={600}
              timingFunction="ease"
            >
              {(styles) =>
                <Group align="center" style={styles}>
                  {!isMobile &&
                    <Transition
                      mounted={transitions.number3}
                      transition="slide-left"
                      duration={400}
                      timingFunction="ease"
                    >
                      {(styles) =>
                        <Box style={styles} className="step-Number">3</Box>}
                    </Transition>
                  }

                  <Stack className="order-section" maw={560} w={"100%"}>
                    <Title order={3} mt={10} className="heading">Invoice Details</Title>

                    <Divider variant="dashed" />

                    <TextInput
                      label="Your Full Name"
                      value={formData.name}
                      onChange={(event) =>
                        setFormData({ ...formData, name: event.target.value })
                      }
                      error={errors.name}
                    />

                    <TextInput
                      label="Email Address"
                      value={formData.email}
                      onChange={(event) =>
                        setFormData({ ...formData, email: event.target.value })
                      }
                      placeholder="user@domain.com"
                      error={errors.email}
                      type="email"
                    />

                    <TextInput
                      label="Phone"
                      description="We shouldn't need to call you, but just in case."
                      value={formData.phone}
                      onChange={(event) =>
                        setFormData({ ...formData, phone: event.target.value })
                      }
                      placeholder="(530) xxx-xxxx"
                      error={errors.phone}
                      type="tel"
                    />
                  </Stack>
                </Group>
              }
            </Transition>

            <Transition
              mounted={transitions.step4}
              transition="slide-up"
              duration={600}
              timingFunction="ease"
            >
              {(styles) =>
                <Group align="center" style={styles}>
                  {!isMobile &&
                    <Transition
                      mounted={transitions.number4}
                      transition="slide-left"
                      duration={400}
                      timingFunction="ease"
                    >
                      {(styles) =>
                        <Box style={styles} className="step-Number">4</Box>}
                    </Transition>
                  }

                  <Stack className="order-section" maw={560} w={"100%"} gap={20}>
                    <Title order={3} mt={10} className="heading">What are the next steps?</Title>

                    <Divider variant="dashed" />

                    <Text><b>We will:</b></Text>
                    <ol style={{ margin: 0, paddingLeft: 20, lineHeight: 2, marginTop: -10 }}>
                      <li>Print & assemble your Water Grate.</li>
                      <li>Deliver the grate to the location you selected above.</li>
                      <li>After delivery, we'll email you an invoice for online payment.</li>
                    </ol>

                    <Box>
                      <Button
                        disabled={formData.quantity === 0 || isSubmitting}
                        type="submit"
                        size="lg"
                        mt={10}
                        bg={formData.quantity === 0 ? "lightgray" : "#c17367"}
                      >Submit Order</Button>
                    </Box>

                    {isSubmitted && <Alert color="green">Your order has been submitted!</Alert>}
                  </Stack>
                </Group>
              }
            </Transition>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
}
