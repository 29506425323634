import { Button, Group, Stack, Text } from "@mantine/core";

export function OrderButton({children = "Order Now"}: {children: React.ReactNode}) {
  // const paymentURL = "https://buy.stripe.com/4gw8A8fDn3Ak5AQ6oo?prefilled_promo_code=earlybird"

  return (
    <Group>
      <Button
        component="a"
        href="/order"
        size="md"
        color={"#c17367"}
      >
        {children}
      </Button>
    </Group>
  );
}export function Delivery() {
  return (
    <>
      <Text className="para">
        This is the part of the project I have the most concerns about.
        During the purchase process, I'll ask for a physical location
        where I can drop off the Water Grate. My hope is that people
        select a location within 5 miles of downtown Grass Valley. If
        the entered drop location is too inconvenient, I will have to get intouch with you to get a different address.
      </Text>

      <Text className="para">
        Using a friend's, family member's, or coworker's house as the
        drop location is totally fine. The product will be dropped off
        as quickly and efficiently as possible.
      </Text>

      <Stack gap={"xs"}>
        <Text className="para">
          <b style={{ color: "#777" }}>
            Example areas that could work as drop locations:
          </b>
        </Text>

        <ul style={{ margin: 0 }}>
          <li>
            <Text className="para">
              Anywhere in or near the downtown Grass Valley, Nevada
              City, Brunswick, etc areas.
            </Text>
          </li>
          <li>
            <Text className="para">
              Loma Rica Airport area / Whispering Pines area.
            </Text>
          </li>
          <li>
            <Text className="para">
              Penn Valley around Western Gateway Park, Ready Springs,
              etc.
            </Text>
          </li>
          <li>
            <Text className="para">Rough and Ready Area.</Text>
          </li>
          <li>
            <Text className="para">
              Alta Sierra locations are also OK.
            </Text>
          </li>
        </ul>
      </Stack>

      <Stack gap={"xs"}>
        <Text className="para">
          <b style={{ color: "#777" }}>
            Please avoid these areas for drop locations:
          </b>
        </Text>

        <ul style={{ margin: 0 }}>
          <li>
            <Text className="para">Colfax / Chicago park areas</Text>
          </li>
          <li>
            <Text className="para">
              Up on the ridge, North San Juan, Camptonville
            </Text>
          </li>
          <li>
            <Text className="para">
              Way out in the surrounding Pentucky area
            </Text>
          </li>
          <li>
            <Text className="para">
              Way down Green Horn Road, You Bet Road, Banner Mountain
              Roads, Rattle Snake Road, etc
            </Text>
          </li>
          <li>
            <Text className="para">Up Highway 20</Text>
          </li>
        </ul>
      </Stack>

      <Text className="para">
        I fully realize that the areas I am discouraging for pickups are
        the exact areas that would have the highest need for this
        product. As this project goes on, I'll try to expand the
        delivery areas.
      </Text>
    </>
  );
}

